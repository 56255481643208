import servicesAPI from "api/services";

import { createAppAsyncThunk } from "store/duck/types";

import { RegionsDictionary } from "./duck/types";
import { REDUNDANT_UNITED_STATES } from "./duck/constants";

export const loadRegions = createAppAsyncThunk(
  "regions/loadRegions",
  async (_, { getState }) => {
    const {
      regions: { isLoaded, data },
    } = getState();

    if (isLoaded) {
      return data;
    }

    const regions = await servicesAPI.fetchAllRegions();
    const result: RegionsDictionary = {};
    for (const region of regions) {
      result[region.letterId] = {
        ...region,
        states: region.states.reduce((acc, state) => {
          if (
            region.letterId === "usa" &&
            REDUNDANT_UNITED_STATES.includes(state.letterId)
          ) {
            return acc;
          }

          return Object.assign(acc, { [state.letterId]: state });
        }, {}),
      };
    }

    return result;
  },
);
