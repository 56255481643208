import { insightsAPI } from "api/insights";

import { createAsyncServerThunk } from "operations/redux";

export const loadCategoriesServer = createAsyncServerThunk(
  "insights/loadInsightsCategories",
  () => insightsAPI.fetchCategories(),
  {
    selectResult: ({ insights: { isLoadedCategories, categories } }) => {
      if (isLoadedCategories) {
        return categories;
      }
    },
  },
);
