import accountAPI, { VerifyAuthCodeBody } from "api/account";
import paymentAPI from "api/payment";

import { storageActions } from "store/storage";
import { addressesCookieStorageData, addressesSlice } from "store/addresses";
import { coinsSlice, coinsCookiesStorageData } from "store/coins";

import { debounceAsync, promiseEater } from "operations/async";
import { getPaymentOptionsIds } from "operations/payment";

import { createAppAsyncThunk } from "../duck/types";
import { getIsAuthorized, selectCurrency } from "./duck/selectors";

interface AccountLoadDTO {
  account: Account;
  paymentMethods: PaymentMethod[];
}

export const logout = createAppAsyncThunk<void, void>(
  "account/logout",
  async (_, { dispatch }) => {
    await accountAPI.logout();
    dispatch(coinsSlice.actions.unsetFavorites());
  },
);

export const verifyAuthCode = createAppAsyncThunk<
  AccountLoadDTO,
  { email: string; code: string }
>("account/verifyAuthCode", async ({ code, email }, { getState, dispatch }) => {
  const {
    addresses: { ids, entities },
    storage: { currency: currencyName },
    currencies: {
      entities: { [currencyName]: currency },
    },
  } = getState();
  const favorites = coinsCookiesStorageData.favoriteIds;
  const data: VerifyAuthCodeBody = { code, email };
  if (ids.length) {
    data.addresses = ids.map(id => {
      const item = entities[id];

      return {
        address: item.address,
        addressName: item.addressName,
        network: item.network.id,
      };
    });
  }

  if (currency) {
    data.currency = currency.id;
  }

  if (favorites.length) {
    data.favorites = favorites;
  }

  const { account, paymentMethods, addresses } =
    await accountAPI.verifyAuthCode(data);

  coinsCookiesStorageData.clear();
  addressesCookieStorageData.clear();

  dispatch(storageActions.remove({ key: "currency" }));
  dispatch(addressesSlice.actions.setAddresses(addresses));
  dispatch(coinsSlice.actions.clearCoins());

  return { account, paymentMethods };
});

export const updateAccountCurrency = createAppAsyncThunk<
  Account,
  CurrencyWithLimits
>(
  "account/updateAccountCurrency",
  debounceAsync(({ id }) => accountAPI.updateAccount({ currency: id }), 300),
);

export const changeCurrency = createAppAsyncThunk<void, CurrencyWithLimits>(
  "account/changeCurrency",
  promiseEater((currency, { dispatch, getState }) => {
    const isAuthorized = getIsAuthorized(getState());
    if (!isAuthorized) {
      return dispatch(
        storageActions.set({ key: "currency", value: currency.name }),
      );
    }

    return dispatch(updateAccountCurrency(currency));
  }),
  {
    condition: ({ id }, { getState }) => {
      const currency = selectCurrency(getState());

      return id !== currency.id;
    },
  },
);

export const loadPaymentMethods = createAppAsyncThunk<PaymentMethod[], void>(
  "account/loadPaymentMethods",
  () => paymentAPI.fetchPaymentMethods(),
  {
    condition(_, { getState }) {
      if (!getIsAuthorized(getState())) {
        return false;
      }
    },
  },
);

type ClientPaymentMethod = Omit<PaymentMethod, "id" | "isActive" | "createdAt">;

export const createPaymentMethod = createAppAsyncThunk<
  PaymentMethod,
  ClientPaymentMethod
>("account/createPaymentMethod", data =>
  paymentAPI.createPaymentMethod({
    currency: data.currency.id,
    region: data.region.id,
    paymentMethods: getPaymentOptionsIds(data.paymentOptions),
    isActive: true,
  }),
);

export const updatePaymentMethod = createAppAsyncThunk<
  PaymentMethod,
  { paymentMethod: PaymentMethod; isActivityChange?: boolean }
>("account/updatePaymentMethod", ({ paymentMethod }, { getState }) => {
  const {
    account: { paymentMethods },
  } = getState();
  const activeIds = paymentMethods.ids.filter(
    id => paymentMethods.entities[id].isActive,
  );

  const { id, ...data } = paymentMethod;
  if (activeIds.length <= 1 && !data.isActive) {
    const error = new Error("You must have at least one active payment method");

    error.name = "ChangeActiveError";

    throw error;
  }

  return paymentAPI.updatePaymentMethod(id, {
    currency: data.currency.id,
    region: data.region.id,
    paymentMethods: getPaymentOptionsIds(data.paymentOptions),
    isActive: data.isActive,
  });
});

export const removePaymentMethod = createAppAsyncThunk<
  void,
  PaymentMethod["id"]
>("account/removePaymentMethod", async id => {
  await paymentAPI.removePaymentMethod(id);
});
