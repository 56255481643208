import { PayloadAction } from "@reduxjs/toolkit";
import { addressesAdapter } from "./duck/operations";
import { ReducerState } from "./duck/types";

const reducers = {
  setAddresses: (
    state: ReducerState,
    { payload }: PayloadAction<Address[]>,
  ) => {
    addressesAdapter.setAll(state, payload);
    state.isLoaded = true;
  },
};

export default reducers;
