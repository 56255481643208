import { createSlice } from "@reduxjs/toolkit";

import type { ExtraInitialState } from "./duck/types";
import { coinsAdapter } from "./duck/operations";

import reducers from "./reducers";
import extraReducers from "./extraReducers";

export const coinsSlice = createSlice({
  name: "coins",
  initialState: coinsAdapter.getInitialState<ExtraInitialState>({
    isCoinLoading: true,
    isLoading: true,
    isNextCoins: false,
    coinsError: null,
    loadMoreError: null,
    coinDetailsError: null,
    loadFilter: null,
    categories: [],
    isLoadedCategories: false,
  }),
  reducers,
  extraReducers,
});
