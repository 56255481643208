import { createSlice } from "@reduxjs/toolkit";

import reducers from "./reducers";
import extraReducers from "./extraReducers";
import { InitialState } from "./duck/types";

export const regionsSlice = createSlice({
  name: "regions",
  initialState: {
    data: {},
    isLoaded: false,
    error: null,
    isLoading: false,
  } as InitialState,
  reducers,
  extraReducers,
});
