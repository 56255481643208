import {
  createAsyncThunk,
  type ListenerMiddlewareInstance,
} from "@reduxjs/toolkit";

import { configure } from "../configure";

export type AppStore = ReturnType<typeof configure>["store"];

export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string;
  meta: unknown;
}>();

export type Watcher = ListenerMiddlewareInstance<
  ReturnType<AppStore["getState"]>,
  AppStore["dispatch"]
>;
