import accountAPI from "api/account";
import paymentAPI from "api/payment";

import { addressesCookieStorageData } from "store/addresses";
import { coinsCookiesStorageData } from "store/coins";

import { createAsyncServerThunk } from "operations/redux";
import { NetworkError } from "operations/error";

export const loadAccountServer = createAsyncServerThunk(
  "account/loadAccountServer",
  async () => {
    const account = await accountAPI.getAccount();

    if (account) {
      coinsCookiesStorageData.clear();
      addressesCookieStorageData.clear();
    }

    return account;
  },
  {
    selectResult: state => {
      if (state.account.isLoaded) {
        return state.account.data;
      }
    },
  },
);

export const loadPaymentMethodsServer = createAsyncServerThunk(
  "account/loadPaymentMethodsServer",
  async () => {
    try {
      return await paymentAPI.fetchPaymentMethods();
    } catch (error) {
      if (error instanceof NetworkError && error.responseStatus === 401) {
        return [];
      }

      throw error;
    }
  },
);
