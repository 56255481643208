import { createSlice } from "@reduxjs/toolkit";

import reducers from "./reducers";
import extraReducers from "./extraReducers";

import { currenciesAdapter } from "./duck/operations";
import { ExtraCurrenciesState } from "./duck/types";

export const currenciesSlice = createSlice({
  name: "currencies",
  initialState: currenciesAdapter.getInitialState<ExtraCurrenciesState>({
    isLoading: false,
    error: null,
    isLoaded: false,
  }),
  reducers,
  extraReducers,
});
