import dialogAPI from "operations/dialog";

import AlertComponent from "./Alert";

const openAlert = ({ message, type = "error" }: Partial<Alert>) =>
  dialogAPI.open(<AlertComponent message={message} type={type} />, {
    type: "alert",
  });

export default openAlert;
