// TODO: delete all redundant states logic when fixed on server
export const REDUNDANT_UNITED_STATES = [
  "american-samoa",
  "federated-states-of-micronesia",
  "guam",
  "marshall-islands-2",
  "northern-mariana-islands",
  "puerto-rico",
  "palau-2",
  "virgin-islands-us",
];
