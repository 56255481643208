import Big from "bignumber.js";

import { DEFAULT_SHORTEN_KMB } from "../constants/numbers";

Big.config({
  // make big return full notation for precision 7
  EXPONENTIAL_AT: -8,
  ROUNDING_MODE: Big.ROUND_DOWN,
});

export const tryToBig = (value?: Big | number | string | null) => {
  // Cause of undefined creates unusable new Big constructor
  if (!value && value !== 0) {
    return null;
  }

  try {
    return Big(value);
  } catch {
    return null;
  }
};

export const getIsTooSmallForPrecision = (value: Big, precision: number) => {
  const minPrecisionAmount = Big(1).div(10).pow(precision);
  const isValueTooSmall = value.lt(minPrecisionAmount) && value.gt(0);
  return { minPrecisionAmount, isValueTooSmall };
};

interface GetNumberPrecisionData {
  value: Big;
  precision?: Precisions;
  isExternal?: boolean;
  isInteger?: boolean;
  isPrice?: boolean;
  isInteractive?: boolean;
  isKMB?: boolean;
}

const getNumberPrecisionData = ({
  value,
  precision,
  isExternal = false,
  isInteger = false,
  isPrice = false,
  isInteractive = false,
}: GetNumberPrecisionData) => {
  const MAX_THOUSANDS_PRECISION_NUMBER = 1000;
  const MAX_HUNDREDS_PRECISION_NUMBER = 100;
  const MAX_FLOATS_PRECISION_NUMBER = 0.01;

  let precisionToUse = precision;

  if (!precisionToUse && precisionToUse !== 0) {
    // values also can be negative
    const positiveValue = value.abs();
    const isZero = positiveValue.eq(0);

    switch (true) {
      case isInteger:
      case isZero && !isExternal:
      case positiveValue.gte(MAX_THOUSANDS_PRECISION_NUMBER): {
        precisionToUse = 0;
        break;
      }

      case isInteractive: {
        precisionToUse = 7;
        break;
      }

      case isExternal && !isPrice:
      case isExternal && isZero:
      case positiveValue.gte(MAX_HUNDREDS_PRECISION_NUMBER): {
        precisionToUse = 2;
        break;
      }

      case positiveValue.lt(MAX_HUNDREDS_PRECISION_NUMBER) &&
        positiveValue.gte(MAX_FLOATS_PRECISION_NUMBER): {
        precisionToUse = 4;
        break;
      }

      default: {
        precisionToUse = 7;
      }
    }
  }

  const { minPrecisionAmount, isValueTooSmall } = getIsTooSmallForPrecision(
    value,
    precisionToUse,
  );

  return { precisionToUse, minPrecisionAmount, isValueTooSmall };
};

const getKMB = (value: Big) => {
  let values;

  switch (true) {
    case value.gte(1e15):
      values = { kmbValue: value.div(1e15), postfix: "Q" };
      break;
    case value.gte(1e12):
      values = { kmbValue: value.div(1e12), postfix: "T" };
      break;
    case value.gte(1e9):
      values = { kmbValue: value.div(1e9), postfix: "B" };
      break;
    case value.gte(1e6):
      values = { kmbValue: value.div(1e6), postfix: "M" };
      break;
    default:
      values = {
        kmbValue: value,
        postfix: "",
      };
  }

  return {
    kmbValue: values.kmbValue.decimalPlaces(2, Big.ROUND_HALF_UP).toFixed(2),
    postfix: values.postfix,
  };
};

const getSplittedNumber = (formattedString: string) => {
  if (!formattedString.includes(".")) {
    return { numberPart: formattedString, zerosPart: "" };
  }

  let zerosPart = "";
  for (let i = formattedString.length - 1; formattedString[i] === "0"; i--) {
    zerosPart += formattedString[i];
  }

  return {
    numberPart: formattedString.slice(
      0,
      formattedString.length - zerosPart.length,
    ),
    zerosPart,
  };
};

interface GetNumberDataArgs {
  value: Big;
  precision?: Precisions;
  isKMB?: boolean;
  minShortenKMB?: number;
  isExternal?: boolean;
  isInteger?: boolean;
  isPrice?: boolean;
  isInteractive?: boolean;
}

export const getNumberData = (
  {
    value,
    precision,
    isKMB = false,
    minShortenKMB = DEFAULT_SHORTEN_KMB,
    isExternal = false,
    isInteger = false,
    isPrice = false,
    isInteractive = false,
  } = {} as GetNumberDataArgs,
) => {
  const { precisionToUse, minPrecisionAmount, isValueTooSmall } =
    getNumberPrecisionData({
      value,
      precision,
      isExternal,
      isInteger,
      isPrice,
      isInteractive,
      isKMB,
    });
  const valueToFormat = isValueTooSmall ? minPrecisionAmount : value;

  const roundedValue = valueToFormat.decimalPlaces(
    precisionToUse,
    Big.ROUND_HALF_UP,
  );

  const { kmbValue, postfix } =
    !isKMB || roundedValue.lt(minShortenKMB)
      ? {
          postfix: "",
          kmbValue: roundedValue.toFixed(precisionToUse),
        }
      : getKMB(roundedValue);

  const [left = "", right = ""] = kmbValue.split(".");

  // Split big numbers with, or leave it as is
  const leftPart = postfix ? left : left.replace(/(.)(?=(\d{3})+$)/g, "$1,");
  const rightPart = postfix ? right : right.slice(0, precisionToUse);

  const numberFormatted =
    leftPart && rightPart ? `${leftPart}.${rightPart}${postfix}` : leftPart;

  const { numberPart, zerosPart } = getSplittedNumber(numberFormatted);

  return {
    numberBig: isValueTooSmall ? minPrecisionAmount : roundedValue,
    numberString: roundedValue.toFixed(precisionToUse),
    numberFormatted,
    numberPart,
    zerosPart,
    minPrecisionAmount,
    isValueTooSmall,
  };
};
