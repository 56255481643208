import { find } from "lodash";

import servicesAPI from "api/services";

import { createAsyncServerThunk } from "operations/redux";
import { REDUNDANT_UNITED_STATES } from "./duck/constants";

export const loadRegionServer = createAsyncServerThunk<
  Region,
  Region["letterId"] | void
>(
  "regions/loadRegionServer",
  async letterId => {
    const { states, ...region } = await servicesAPI.fetchRegion(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      REDUNDANT_UNITED_STATES.includes(letterId) ? "singapore" : letterId,
    );

    return {
      ...region,
      states: states.reduce((acc, state) => {
        if (
          region.letterId === "usa" &&
          REDUNDANT_UNITED_STATES.includes(state.letterId)
        ) {
          return acc;
        }

        return Object.assign(acc, { [state.letterId]: state });
      }, {}),
    };
  },
  {
    selectResult({ regions }, letterId: Region["letterId"]) {
      return (
        regions.data[letterId] ||
        find(regions.data, ({ states }) => letterId in states)?.states[letterId]
      );
    },
  },
);
