import { PaymentMethodStatuses } from "types/payment";

import { openAlert } from "components/dialog";

import { AccountBuilder } from "./duck/types";
import { paymentAdapter } from "./duck/operations";
import {
  createPaymentMethod,
  loadPaymentMethods,
  logout,
  removePaymentMethod,
  updateAccountCurrency,
  updatePaymentMethod,
  verifyAuthCode,
} from "./thunks";
import { loadAccountServer, loadPaymentMethodsServer } from "./serverThunks";

const extraReducers = (builder: AccountBuilder) => {
  //#region loadAccountServer

  builder
    .addCase(loadAccountServer.pending, state => {
      state.isLoading = true;
    })
    .addCase(loadAccountServer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.data = action.payload;
    })
    .addCase(loadAccountServer.rejected, state => {
      state.isLoaded = true;
      state.isLoading = false;
    });

  //#endregion

  //#region loadPaymentMethodsServer

  builder
    .addCase(loadPaymentMethodsServer.pending, state => {
      state.error = null;
      state.isLoadingPaymentMethods = true;
    })
    .addCase(loadPaymentMethodsServer.fulfilled, (state, action) => {
      state.isLoadingPaymentMethods = false;
      paymentAdapter.setAll(state.paymentMethods, action.payload);
      state.isLoadedPaymentMethods = true;
    })
    .addCase(loadPaymentMethodsServer.rejected, state => {
      state.isLoadingPaymentMethods = false;
      state.isLoadedPaymentMethods = true;
    });

  //#endregion

  //#region logout

  builder
    .addCase(logout.pending, state => {
      state.isLoading = true;
    })
    .addCase(logout.fulfilled, state => {
      state.isLoading = false;
      state.isLoaded = true;
      state.data = null;
      state.error = null;
      paymentAdapter.removeAll(state.paymentMethods);
      state.isLoadedPaymentMethods = false;
    })
    .addCase(logout.rejected, (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = action.error.message;
    });

  //#endregion

  //#region verifyAuthCode

  builder
    .addCase(
      verifyAuthCode.fulfilled,
      (state, { payload: { paymentMethods, account } }) => {
        state.error = null;
        state.isLoading = false;
        state.isLoaded = true;
        state.isLoadedPaymentMethods = true;
        state.data = account;
        paymentAdapter.setAll(state.paymentMethods, paymentMethods);
      },
    )
    .addCase(verifyAuthCode.rejected, (state, action) => {
      state.error = action.error.message;
    });

  //#endregion

  //#region updateAccountCurrency

  builder
    .addCase(updateAccountCurrency.pending, (state, action) => {
      state.isLoading = true;
      state.currency = action.meta.arg.name;
    })
    .addCase(updateAccountCurrency.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.currency = null;
    })
    .addCase(updateAccountCurrency.rejected, (state, { error }) => {
      if (error.name === "AbortError") {
        return;
      }

      state.isLoading = false;
      state.currency = null;

      openAlert({ message: "An error occurred while updating the currency" });
    });

  //#endregion

  //#region loadPaymentMethods

  builder
    .addCase(loadPaymentMethods.pending, state => {
      state.error = null;
      state.isLoadingPaymentMethods = true;
    })
    .addCase(loadPaymentMethods.fulfilled, (state, action) => {
      state.isLoadingPaymentMethods = false;
      paymentAdapter.setAll(state.paymentMethods, action.payload);
      state.isLoadedPaymentMethods = true;
    })
    .addCase(loadPaymentMethods.rejected, (state, action) => {
      state.isLoadingPaymentMethods = false;
      state.isLoadedPaymentMethods = true;
      state.error = action.error.message;
    });

  //#endregion

  //#region createPaymentMethod

  builder
    .addCase(createPaymentMethod.pending, state => {
      state.isCreatingPaymentMethod = true;
    })
    .addCase(createPaymentMethod.fulfilled, (state, action) => {
      state.isCreatingPaymentMethod = false;
      paymentAdapter.addOne(state.paymentMethods, action.payload);

      openAlert({
        type: "success",
        message: "Payment method was created successfully",
      });
    })
    .addCase(createPaymentMethod.rejected, state => {
      state.isCreatingPaymentMethod = false;

      openAlert({
        message: "An error occurred while creating the payment method",
      });
    });

  //#endregion

  //#region updatePaymentMethod

  builder
    .addCase(updatePaymentMethod.pending, (state, action) => {
      const isActivityChange = action.meta.arg.isActivityChange;
      state.paymentMethodStatuses[action.meta.arg.paymentMethod.id] =
        isActivityChange
          ? PaymentMethodStatuses.activityChanging
          : PaymentMethodStatuses.editing;
    })
    .addCase(updatePaymentMethod.fulfilled, (state, action) => {
      state.paymentMethodStatuses[action.meta.arg.paymentMethod.id] = null;
      paymentAdapter.upsertOne(state.paymentMethods, action.payload);

      if (!action.meta.arg.isActivityChange) {
        openAlert({
          type: "success",
          message: "Payment method was updated successfully",
        });
      }
    })
    .addCase(updatePaymentMethod.rejected, (state, action) => {
      state.paymentMethodStatuses[action.meta.arg.paymentMethod.id] = null;

      let message = "An error occurred while updating the payment method";
      if (action.error.name === "ChangeActiveError") {
        message = action.error.message;
      }

      openAlert({
        message,
      });
    });

  //#endregion

  //#region updatePaymentMethod

  builder
    .addCase(removePaymentMethod.pending, (state, action) => {
      state.paymentMethodStatuses[action.meta.arg] =
        PaymentMethodStatuses.removing;
    })
    .addCase(removePaymentMethod.fulfilled, (state, action) => {
      state.paymentMethodStatuses[action.meta.arg] = null;
      paymentAdapter.removeOne(state.paymentMethods, action.meta.arg);

      openAlert({
        type: "success",
        message: "Payment method was deleted successfully",
      });
    })
    .addCase(removePaymentMethod.rejected, (state, action) => {
      state.paymentMethodStatuses[action.meta.arg] = null;

      openAlert({
        message: "An error occurred while deleting the payment method",
      });
    });

  //#endregion
};

export default extraReducers;
