import { processPaymentMethod } from "operations/payment";

import { authInstance } from "./instances/coindisco";

interface UpdatePaymentMethodDTO {
  paymentMethods: PaymentOption["id"][];
  currency: CurrencyWithLimits["id"];
  region: Region["id"];
  isActive: boolean;
}

const paymentAPI = new (class {
  public async fetchPaymentMethods(): Promise<PaymentMethod[]> {
    const { data } = await authInstance.get(`payments/payment-methods/`);

    return data.map(processPaymentMethod);
  }

  public async createPaymentMethod(
    data: UpdatePaymentMethodDTO,
  ): Promise<PaymentMethod> {
    const result = await authInstance.post<PaymentMethodDTO>(
      "payments/payment-methods/",
      data,
    );

    return processPaymentMethod(result.data);
  }

  public async updatePaymentMethod(
    id: PaymentMethod["id"],
    data: UpdatePaymentMethodDTO,
  ): Promise<PaymentMethod> {
    const result = await authInstance.patch<PaymentMethodDTO>(
      `payments/payment-methods/${id}/`,
      data,
    );

    return processPaymentMethod(result.data);
  }

  public async removePaymentMethod(id: PaymentMethod["id"]) {
    const { data } = await authInstance.delete<undefined>(
      `payments/payment-methods/${id}/`,
    );

    return data;
  }
})();

export default paymentAPI;
