import { createSlice } from "@reduxjs/toolkit";

import reducers from "./reducers";
import extraReducers from "./extraReducers";
import { ExtraAddressesState } from "./duck/types";
import { addressesAdapter } from "./duck/operations";

export const addressesSlice = createSlice({
  name: "addresses",
  initialState: addressesAdapter.getInitialState<ExtraAddressesState>({
    isLoading: false,
    isLoaded: false,
    isCreating: false,
    statuses: {},
    error: null,
  }),
  reducers,
  extraReducers,
});
