import { formatISO } from "date-fns";

import addressesAPI, {
  CreateAddressArg,
  UpdateAddressArg,
} from "api/addresses";
import coinsAPI from "api/coins";

import { createAppAsyncThunk } from "../duck/types";
import { getIsAuthorized } from "../account/duck/selectors";
import { cookieStorageData } from "./duck/operations";

export const loadAddresses = createAppAsyncThunk<Address[], void>(
  "addresses/loadAddresses",
  (_, { getState }) => {
    const isAuthorized = getIsAuthorized(getState());
    if (!isAuthorized) {
      return Object.values(cookieStorageData.walletAddresses);
    }

    return addressesAPI.fetchAddresses();
  },
);

export const createAddress = createAppAsyncThunk<Address, CreateAddressArg>(
  "addresses/createAddress",
  async (data, { getState }) => {
    const state = getState();
    const isAuthorized = getIsAuthorized(state);

    if (!isAuthorized) {
      const localAddresses = cookieStorageData.walletAddresses;
      const localAddressesList = Object.values(localAddresses);

      let newId = localAddressesList.length + 1;

      localAddressesList.forEach(({ id }) => {
        const currentId = Number(id);
        if (currentId >= newId) {
          newId = currentId + 1;
        }
      });

      const { address, network: networkId, cryptocurrency: coinId } = data;

      const targetCoin = state.coins.entities[coinId];

      const networks =
        targetCoin?.networks || (await coinsAPI.fetchCoin(coinId)).networks;

      const network = networks.find(({ id }) => id === networkId);

      const newAddress = {
        id: newId,
        address,
        addressName: "",
        network,
        createdAt: formatISO(new Date(), { format: "extended" }),
      };

      localAddresses[newId] = newAddress;

      cookieStorageData.setWalletAddresses(localAddresses);

      return newAddress;
    }

    return await addressesAPI.createAddress(data);
  },
);

export const updateAddress = createAppAsyncThunk<Address, UpdateAddressArg>(
  "addresses/updateAddress",
  async (data, { getState }) => {
    const isAuthorized = getIsAuthorized(getState());

    if (!isAuthorized) {
      const localAddresses = cookieStorageData.walletAddresses;

      localAddresses[data.id] = {
        ...localAddresses[data.id],
        ...data,
      };

      cookieStorageData.setWalletAddresses(localAddresses);

      return localAddresses[data.id];
    }

    return await addressesAPI.updateAddress(data);
  },
);

export const removeAddress = createAppAsyncThunk<void, Address["id"]>(
  "addresses/removeAddress",
  async (id, { getState }) => {
    const isAuthorized = getIsAuthorized(getState());

    if (!isAuthorized) {
      const localAddresses = cookieStorageData.walletAddresses;

      delete localAddresses[id];

      cookieStorageData.setWalletAddresses(localAddresses);

      return;
    }

    return await addressesAPI.removeAddress(id);
  },
);
