import omit from "lodash/omit";

import { createSlice, type PayloadAction, type Store } from "@reduxjs/toolkit";

import { IS_SERVER } from "constants/env";

class FakeStorage implements Storage {
  private store = new Map<string, string>();

  public get length(): number {
    return this.store.size;
  }

  public clear(): void {
    this.store.clear();
  }

  public getItem(key: string): string {
    return this.store.get(key);
  }

  public key(index: number): string {
    return this.store.keys()[index];
  }

  public removeItem(key: string): void {
    this.store.delete(key);
  }

  public setItem(key: string, value: string): void {
    this.store.set(key, value);
  }
}

class ObjectStorage implements CustomStorage {
  constructor(private readonly storage: Storage) {}

  public get length(): number {
    return this.storage.length;
  }

  public set<T>(key: string, value: T): void {
    return this.storage.setItem(key, JSON.stringify(value));
  }

  public get<T>(key: string): T | null {
    const jsonData = this.storage.getItem(key);
    if (!jsonData) {
      return null;
    }

    return JSON.parse(jsonData);
  }

  public remove(key: string): void {
    this.storage.removeItem(key);
  }

  public clear(): void {
    this.storage.clear();
  }
}

interface ChangeEvent {
  key: string;
  previous: unknown;
  current: unknown;
  isMainMutator: boolean;
}

interface Listener {
  (event: ChangeEvent): void;
}

class ReactiveLocalStorage extends ObjectStorage {
  private subscribers = new Map<string, Set<Listener>>();

  public subscribe(key: string, listener: Listener): VoidFunction {
    let keySubscribers = this.subscribers.get(key);
    if (!keySubscribers) {
      keySubscribers = new Set([listener]);
    } else {
      keySubscribers.add(listener);
    }

    this.subscribers.set(key, keySubscribers);

    return () => {
      this.subscribers.get(key)?.delete(listener);
    };
  }

  private emit(event: ChangeEvent): void {
    const keySubscribers = this.subscribers.get(event.key);
    if (!keySubscribers) {
      return;
    }

    for (const subscriber of keySubscribers) {
      subscriber(event);
    }
  }

  public set<T>(key: string, value: T): void {
    const previous = this.get(key);

    super.set(key, value);
    this.emit({ previous, current: value, key, isMainMutator: true });
  }

  public remove(key: string): void {
    const previous = this.get(key);

    super.remove(key);
    this.emit({ previous, key, current: null, isMainMutator: true });
  }

  public clear(): void {
    const prevValues = new Map<string, unknown>();
    for (const [key] of this.subscribers) {
      prevValues.set(key, this.get(key));
    }

    super.clear();
    for (const [key, subscribers] of this.subscribers) {
      for (const subscriber of subscribers) {
        subscriber({
          key,
          current: null,
          previous: prevValues.get(key),
          isMainMutator: true,
        });
      }
    }
  }

  public subscribeWindow() {
    window.addEventListener("storage", ({ newValue, oldValue, key }) => {
      this.emit({
        current: JSON.parse(newValue),
        key,
        previous: JSON.parse(oldValue),
        isMainMutator: false,
      });
    });
  }

  public createSlice<T extends object>(initial: T) {
    const keys = Object.keys(initial);
    const unsubscribers = new Map<keyof T, VoidFunction>();
    type ActionType = PayloadAction<{ key: keyof T; value: ValueOf<T> }>;

    const initialState = keys.reduce(
      (acc, key) =>
        Object.assign(acc, { [key]: this.get(key) || initial[key] }),
      {} as T,
    );

    const storageSlice = createSlice({
      name: "storage",
      initialState,
      reducers: {
        set: (state, { payload: { key, value } }: ActionType) => {
          this.set(key as string, value);

          return {
            ...state,
            [key]: value,
          };
        },
        change: (state, { payload: { key, value } }: ActionType) => ({
          ...state,
          [key]: value,
        }),
        remove: (
          state,
          { payload: { key } }: PayloadAction<{ key: keyof T }>,
        ) => {
          this.remove(key as string);

          return omit(state, key) as T;
        },
      },
    });

    return {
      storageSlice,
      subscribe: (store: Store) => {
        for (const key of keys) {
          unsubscribers.set(
            key as keyof T,
            this.subscribe(key, ({ isMainMutator, current }) => {
              if (isMainMutator) {
                return;
              }

              store.dispatch(
                storageSlice.actions.change({
                  key: key as keyof T,
                  value: current as ValueOf<T>,
                }),
              );
            }),
          );
        }
      },
      unsubscribe: () => {
        unsubscribers.forEach(unsubscribe => unsubscribe());
      },
    };
  }
}

const storages = new (class {
  private local?: ObjectStorage;

  private session?: ObjectStorage;

  private reactiveStorage?: ReactiveLocalStorage;

  private localStorageRef?: Storage;

  private get localOrFake() {
    if (!this.localStorageRef) {
      this.localStorageRef =
        globalThis.window?.localStorage || new FakeStorage();
    }

    return this.localStorageRef;
  }

  public get localStorage() {
    if (!this.local) {
      this.local = new ObjectStorage(this.localOrFake);
    }

    return this.local;
  }

  public get sessionStorage() {
    if (!this.session) {
      this.session = new ObjectStorage(
        IS_SERVER ? new FakeStorage() : window.sessionStorage,
      );
    }

    return this.session;
  }

  public get reactiveLocalStorage() {
    if (!this.reactiveStorage) {
      this.reactiveStorage = new ReactiveLocalStorage(this.localOrFake);
    }

    return this.reactiveStorage;
  }
})();

const localStorageRef = globalThis.window?.localStorage || new FakeStorage();

export const localStorage = new ObjectStorage(localStorageRef);
export const sessionStorage = new ObjectStorage(
  globalThis.window?.sessionStorage || new FakeStorage(),
);
export const reactiveLocalStorage = new ReactiveLocalStorage(localStorageRef);

export default storages;
