import { PAYMENT_OPTIONS } from "constants/payment";

export const getGroupedPaymentOptions = (paymentOptions: PaymentOption[]) => {
  const groupedPaymentOptions = {} as Record<
    PaymentOptionCategory,
    GroupedPaymentOption
  >;

  let newOptionsIndex = Object.values(PAYMENT_OPTIONS).length;

  paymentOptions.forEach(paymentMethod => {
    const categoryOption = groupedPaymentOptions[paymentMethod.category];
    if (categoryOption) {
      categoryOption.ids.push(paymentMethod.id);

      return;
    }

    const index = PAYMENT_OPTIONS[paymentMethod.category];
    const isIndex = typeof index === "number";

    groupedPaymentOptions[paymentMethod.category] = {
      index: isIndex ? index : newOptionsIndex,
      category: paymentMethod.category,
      categoryIcon: paymentMethod.categoryIcon,
      categoryDescription: paymentMethod.categoryDescription,
      ids: [paymentMethod.id],
    };

    if (!isIndex) {
      newOptionsIndex += 1;
    }
  });

  return Object.values(groupedPaymentOptions).sort((a, b) => a.index - b.index);
};

export const getPaymentOptionsIds = (paymentOptions: GroupedPaymentOption[]) =>
  paymentOptions.reduce(
    (acc, paymentOption) => acc.concat(paymentOption.ids),
    [],
  );

export const processPaymentMethod = ({
  paymentMethods,
  ...paymentMethodInfo
}: PaymentMethodDTO): PaymentMethod => ({
  ...paymentMethodInfo,
  paymentOptions: getGroupedPaymentOptions(paymentMethods),
});
