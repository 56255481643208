import { authInstance } from "./instances/coindisco";

export interface CreateAddressArg {
  network: Network["id"];
  address: Address["address"];
  cryptocurrency?: Coin["letterId"];
}

export type UpdateAddressArg = Pick<Address, "id" | "addressName" | "address">;

const addressesAPI = new (class {
  public async fetchAddresses() {
    const result = await authInstance.get<Address[]>(
      "payments/address-wallets/",
    );

    return result.data;
  }

  public async createAddress(data: CreateAddressArg | CreateAddressArg[]) {
    const result = await authInstance.post<Address>(
      "payments/address-wallets/",
      data,
    );

    return result.data;
  }

  public async updateAddress({ id, ...data }: UpdateAddressArg) {
    const result = await authInstance.patch<Address>(
      `payments/address-wallets/${id}/`,
      data,
    );

    return result.data;
  }

  public async removeAddress(id: Address["id"]) {
    await authInstance.delete<void>(`payments/address-wallets/${id}`);
  }
})();

export default addressesAPI;
