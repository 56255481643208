import { authInstance, instance } from "./instances/coindisco";

export interface SearchOptions {
  region: Region["id"];
  paymentMethod: PaymentMethod["id"];
  cryptocurrency: BaseCoin["id"];
  currency: Currency["id"];
  baseCurrency: Currency["id"];
  network: Network["id"];
  currencyAmount: number;
  cryptocurrencyAmount?: number;
}

export interface RetrieveBuyLink {
  region: number;
  cryptocurrency: number;
  currency: number;
  network: number;
  service: number;
  currencyAmount: number;
  paymentMethod: number;
  walletAddress: string;
}

export interface RetrieveBuyLinkResult {
  link: string;
  transactionId: string;
}

export interface SearchProvidersResult {
  status: "in_progress" | "completed";
  searchId: string;
  data: BuyProvider[];
}

const buyCryptoAPI = new (class {
  public async searchByOptions(options: SearchOptions[]) {
    const { data } = await instance.post<{ searchId: string }>(
      "service-integration/search-buy-options/",
      options,
    );

    return data.searchId;
  }

  public async searchProvidersById(searchId: string) {
    const { data } = await instance.get<SearchProvidersResult>(
      `service-integration/search-buy-options/${searchId}/`,
    );

    return data;
  }

  public async retrieveBuyLink(values: RetrieveBuyLink) {
    const { data } = await authInstance.post<{
      link: string;
      transactionId: string;
    }>("service-integration/retrieve-buy-link/", values);

    return data;
  }
})();

export default buyCryptoAPI;
