import { RegionsBuilder } from "./duck/types";
import { loadRegionServer } from "./serverThunks";
import { loadRegions } from "./thunks";

const extraReducers = (builder: RegionsBuilder) => {
  // #region loadRegions
  builder
    .addCase(loadRegions.pending, state => {
      state.error = null;
      state.isLoading = true;
    })
    .addCase(loadRegions.fulfilled, (state, action) => {
      state.isLoaded = true;
      state.isLoading = false;
      state.data = action.payload;
    })
    .addCase(loadRegions.rejected, (state, action) => {
      state.isLoaded = true;
      state.isLoading = false;
      state.error = action.error.message;
    });
  // #endregion

  // #region loadRegionServer

  builder
    .addCase(loadRegionServer.pending, state => {
      state.isLoadingRegion = true;
    })
    .addCase(loadRegionServer.fulfilled, (state, action) => {
      state.isLoadedRegion = true;
      state.isLoadingRegion = false;
      if (action.payload.parentRegion) {
        const data = state.data[action.payload.parentRegion.letterId];

        state.data[action.payload.parentRegion.letterId] = {
          ...data,
          ...action.payload.parentRegion,
          states: {
            ...data?.states,
            [action.payload.letterId]: action.payload,
          },
        };
      } else {
        state.data[action.payload.letterId] = action.payload;
      }
    })
    .addCase(loadRegionServer.rejected, (state, action) => {
      state.isLoadingRegion = false;
      state.isLoadedRegion = true;
      state.error = action.error.message;
    });

  // #endregion
};

export default extraReducers;
