import BigNumber from "bignumber.js";

import { getNumberData } from "selectors/numbers";

export const getGroupedProviders = (providers: BuyProvider[]) => {
  const groups = new Map<string, BuyProviderGrouped>();
  for (const {
    profitRate,
    service,
    region,
    paymentMethod,
    currency,
    totalFee,
    trueFee,
    ...rest
  } of providers) {
    const uniqKey = [
      getNumberData({ value: new BigNumber(profitRate) }).numberString,
      service.id,
      region.id,
      currency.id,
    ].join("");

    const group = groups.get(uniqKey);

    groups.set(uniqKey, {
      uniqKey,
      service,
      region,
      profitRate,
      paymentMethods: (group?.paymentMethods || []).concat(paymentMethod),
      currency,
      trueFee: group?.trueFee > trueFee ? group.trueFee : trueFee,
      totalFee: group?.totalFee > totalFee ? group.totalFee : totalFee,
      ...rest,
    });
  }

  return Array.from(groups.values());
};
