import { IS_SERVER } from "constants/env";

import { configure } from "./configure";

const getPreloadedState = () => {
  if (!IS_SERVER && "[REDUX_STATE]" in window) {
    const store = window["[REDUX_STATE]"];
    delete window["[REDUX_STATE]"];

    return store;
  }

  return {};
};

// Use only in client side
const { store, watcher } = configure({
  preloadedState: getPreloadedState(),
});

export default store;
export { watcher };
