import { createSlice } from "@reduxjs/toolkit";

import reducers from "./reducers";
import extraReducers from "./extraReducers";

import { InitialState } from "./duck/types";
import { paymentAdapter } from "./duck/operations";

export const accountSlice = createSlice({
  name: "account",
  initialState: {
    data: null,
    error: null,
    isLoading: false,
    isLoaded: false,
    currency: null,
    isCreatingPaymentMethod: false,
    isLoadedPaymentMethods: false,
    isLoadingPaymentMethods: true,
    paymentMethods: paymentAdapter.getInitialState(),
    paymentMethodsError: null,
    paymentMethodStatuses: {},
  } as InitialState,
  reducers,
  extraReducers,
});
