import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

import coinsAPI from "api/coins";

import { MAX_FAVORITES_COUNT } from "constants/coins";

import { debounceAsync, promiseEater } from "operations/async";

import { openAlert } from "components/dialog";

import { getIsAuthorized } from "../account/duck/selectors";
import { coinsSlice } from "./coins";
import { createAppAsyncThunk } from "../duck/types";
import { cookiesStorageData } from "./duck/operations";
import { selectById } from "./duck/selectors";

export interface LoadCoinsArgs {
  sort: CoinsSort;
  search: string;
  category: string;
  isLoadMore?: boolean;
}

export const loadCoins = createAppAsyncThunk<
  Awaited<ReturnType<typeof coinsAPI.fetchCoins>>,
  LoadCoinsArgs
>(
  "coins/loadCoins",
  debounceAsync(
    async ({ category, search, sort, isLoadMore }, { getState }) => {
      const state = getState();
      const isAuthorized = getIsAuthorized(state);
      const savedIds = cookiesStorageData.favoriteIds;
      const isFavorites = category === "watchlist";
      const ids = state.coins.ids.map(
        letterId => state.coins.entities[letterId].id,
      );

      const { results, isNextCoins } = await coinsAPI.fetchCoins({
        search,
        ids: isLoadMore ? ids : [],
        favorites: isAuthorized && isFavorites ? "true" : "",
        includedIds: !isAuthorized && isFavorites ? savedIds : [],
        ordering: sort,
        category: category !== "all" && !isFavorites ? category : "",
      });

      let coins = results;

      if (!isAuthorized) {
        const savedIdsMap = {};

        savedIds.forEach(savedId => {
          savedIdsMap[savedId] = true;
        });

        coins = results.map(coin => ({
          ...coin,
          isFavorite: savedIdsMap[coin.id] || false,
        }));
      }

      return { results: coins, isNextCoins };
    },
    300,
  ),
  {
    condition({ isLoadMore, ...rest }, api) {
      const { loadFilter, entities } = api.getState().coins;
      if (!isLoadMore && !isEmpty(entities) && isEqual(rest, loadFilter)) {
        return false;
      }
    },
  },
);

export const loadCoin = createAppAsyncThunk<
  Awaited<ReturnType<typeof coinsAPI.fetchCoin>>,
  Coin["letterId"]
>("coins/loadCoin", async (id, { getState }) => {
  const coin = await coinsAPI.fetchCoin(id);
  if (!getIsAuthorized(getState())) {
    coin.isFavorite = cookiesStorageData.getIsFavorite(coin.id);
  }

  return coin;
});

export const loadPrices = createAppAsyncThunk<
  Awaited<ReturnType<typeof coinsAPI.fetchPrices>>,
  void
>("coins/loadPrices", async () => await coinsAPI.fetchPrices(), {
  condition(_, { getState }) {
    const coinList = Object.values(getState().coins.entities);
    if (!coinList.length) {
      return false;
    }
  },
});

const toggleAuthFavorite = createAppAsyncThunk<void, Coin["id"]>(
  "coins/toggleAuthFavorite",
  debounceAsync(async (id, { getState, dispatch }) => {
    const state = getState();
    const coin = state.coins.entities[id];

    try {
      if (coin.isFavorite) {
        await coinsAPI.addToFavorites(coin.id);
      } else {
        await coinsAPI.removeFromFavorites(coin.id);
      }
    } catch {
      dispatch(
        coinsSlice.actions.setIsFavorite({
          id: coin.letterId,
          isFavorite: !coin.isFavorite,
        }),
      );

      openAlert({
        message: `An error occurred while toggling favorites for ${coin.name}`,
      });
    }
  }, 300),
);

export const toggleFavorite = createAppAsyncThunk<void, Coin["letterId"]>(
  "coins/toggleFavorite",
  promiseEater(async (id, { getState, dispatch }) => {
    const state = getState();
    const coin = selectById(state, id);
    if (!getIsAuthorized(state)) {
      let localIds = cookiesStorageData.favoriteIds;
      if (cookiesStorageData.getIsFavorite(coin.id)) {
        localIds = localIds.filter(id => id !== coin.id);
      } else {
        localIds.push(coin.id);
      }

      cookiesStorageData.setFavorites(localIds);

      return;
    }

    await dispatch(toggleAuthFavorite(id));
  }),
  {
    condition(id, { getState }) {
      const state = getState();
      const isAuthorized = getIsAuthorized(state);
      const isFavorite = state.coins.entities[id]?.isFavorite;

      if (
        !isAuthorized &&
        !isFavorite &&
        cookiesStorageData.favoriteIds.length === MAX_FAVORITES_COUNT
      ) {
        openAlert({
          type: "warning",
          message: `Favorites count cannot be more than ${MAX_FAVORITES_COUNT}`,
        });

        return false;
      }
    },
  },
);
