import { instance } from "./instances/coindisco";

interface Limit {
  service: string;
  minBuyAmount: number | null;
  maxBuyAmount: number | null;
  minBuyAmountUSD: number;
  maxBuyAmountUSD: number;
  paymentMethod: PaymentMethod["id"] | null;
  targetCryptocurrency: BaseCoin["id"] | null;
}

export interface CurrencyWithLimitsDTO extends Currency {
  limits: Limit[];
  rate: number;
}

const currenciesAPI = new (class {
  public async fetchCurrencies() {
    const result = await instance.get<CurrencyWithLimitsDTO[]>(
      "market/v1/currency/",
    );

    return result.data;
  }
})();

export default currenciesAPI;
