import { createEntityAdapter } from "@reduxjs/toolkit";
import { addMonths } from "date-fns";
import { getCookies } from "operations/cookie";

export const coinsAdapter = createEntityAdapter<Coin>({
  selectId: entity => entity.letterId,
});

export const cookiesStorageData = new (class {
  private readonly FAVORITE_KEY = "favorite_coin_ids";

  public get favoriteIds() {
    const rawFavorites = getCookies().get(this.FAVORITE_KEY);
    if (!rawFavorites) {
      return [];
    }

    return rawFavorites.split("-").map(Number);
  }

  public getIsFavorite(id: Coin["id"]) {
    return this.favoriteIds.includes(id);
  }

  public setFavorites(ids: Coin["id"][]) {
    return getCookies().set(this.FAVORITE_KEY, ids.join("-"), {
      path: "/",
      expires: addMonths(new Date(), 3),
    });
  }

  public clear() {
    return getCookies().delete(this.FAVORITE_KEY);
  }
})();
