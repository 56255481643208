import { openAlert } from "components/dialog";

import { PaymentBuilder } from "./duck/types";
import { loadCustomPaymentMethod } from "./thunks";

const extraReducers = (builder: PaymentBuilder) => {
  //#region loadCustomPaymentMethod

  builder
    .addCase(loadCustomPaymentMethod.pending, state => {
      state.error = null;
      state.isLoading = true;
    })
    .addCase(loadCustomPaymentMethod.fulfilled, (state, { meta, payload }) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.isDefault = !meta.arg;
      state.paymentMethod = payload.paymentMethod;
    })
    .addCase(loadCustomPaymentMethod.rejected, (state, { error, meta }) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.isDefault = !meta.arg;
      state.error = error.message;

      if (error.name === "AbortError") {
        return;
      }

      openAlert({
        message: "An error occurred while loading data for custom method",
      });
    });

  //#endregion
};

export default extraReducers;
