import { openAlert } from "components/dialog";

import { BuyCryptoBuilder } from "./duck/types";
import { retrieveBuyLink, loadCoins, searchProviders } from "./thunks";
import { loadCoinServer, loadInsightServer } from "./serverThunks";

const extraReducers = (builder: BuyCryptoBuilder) => {
  //#region loadCoins

  builder
    .addCase(loadCoins.pending, state => {
      state.isLoadedCoins = false;
      state.loadCoinsError = null;
    })
    .addCase(loadCoins.fulfilled, (state, action) => {
      state.isLoadedCoins = true;
      state.coins = action.payload;
    })
    .addCase(loadCoins.rejected, (state, { error }) => {
      state.isLoadedCoins = true;

      if (error.name === "AbortError") {
        return;
      }

      state.loadCoinsError = error.message;
    });

  //#endregion

  //#region loadCoin

  builder
    .addCase(loadCoinServer.pending, state => {
      state.isLoadedCoin = false;
    })
    .addCase(loadCoinServer.fulfilled, (state, action) => {
      state.isLoadedCoin = true;
      state.coin = action.payload;
    })
    .addCase(loadCoinServer.rejected, state => {
      state.isLoadedCoin = true;
    });

  //#endregion

  //#region search

  builder
    .addCase(searchProviders.pending, (state, action) => {
      state.isLoadingProviders = true;
      state.providersError = null;

      if (action.meta.arg.isPrimeLoad) {
        state.providers = [];
        state.isLoadedProviders = false;
      }
    })
    .addCase(searchProviders.fulfilled, state => {
      state.isLoadingProviders = false;
      state.isLoadedProviders = true;
    })
    .addCase(searchProviders.rejected, (state, { error }) => {
      if (error.name === "AbortError") {
        return;
      }

      state.isLoadingProviders = false;
      state.isLoadedProviders = true;

      if (state.providers.length) {
        openAlert({
          type: "warning",
          message: "Some quotes may not have been loaded",
        });
      } else {
        state.providersError = error.message;
      }
    });

  //#endregion

  //#region retrieveBuyLink

  builder
    .addCase(retrieveBuyLink.fulfilled, state => {
      state.providers = [];
    })
    .addCase(retrieveBuyLink.rejected, () => {
      openAlert({ message: "An error occurred while generating the buy link" });
    });

  //#endregion

  //#region loadInsightServer

  builder
    .addCase(loadInsightServer.fulfilled, (state, { payload }) => {
      state.insight = payload;
      state.isLoadedInsight = true;
    })
    .addCase(loadInsightServer.rejected, state => {
      state.isLoadedInsight = true;
    });

  //#endregion
};

export default extraReducers;
