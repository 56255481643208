import { createEntityAdapter } from "@reduxjs/toolkit";
import { addMonths } from "date-fns";

import { getCookies } from "operations/cookie";

export const addressesAdapter = createEntityAdapter<Address>({
  selectId: entity => entity.id,
  sortComparer: (a, b) => b.id - a.id,
});

type WalletAddressesMap = Record<Address["id"], Address>;

export const cookieStorageData = new (class {
  private readonly WALLET_ADDRESSES_KEY = "wallet_addresses";

  public get walletAddresses(): WalletAddressesMap {
    const rawData = getCookies().get(this.WALLET_ADDRESSES_KEY);

    return rawData ? JSON.parse(rawData) : {};
  }

  public setWalletAddresses(walletAddresses: WalletAddressesMap) {
    getCookies().set(
      this.WALLET_ADDRESSES_KEY,
      JSON.stringify(walletAddresses),
      {
        path: "/",
        expires: addMonths(new Date(), 3),
      },
    );
  }

  public clear() {
    getCookies().delete(this.WALLET_ADDRESSES_KEY);
  }
})();
