import { FunctionComponent, SVGProps } from "react";

import SuccessIcon from "icons/success.svg?react";
import ErrorIcon from "icons/error.svg?react";
import WarningIcon from "icons/warning.svg?react";

import { TimeMilliseconds } from "constants/time";

export const ALERT_ICONS_MAP: Record<
  Alert["type"],
  FunctionComponent<SVGProps<SVGSVGElement>>
> = {
  error: ErrorIcon,
  warning: WarningIcon,
  success: SuccessIcon,
};

export const CLOSE_TIMEOUT_MAP: Record<Alert["type"], number> = {
  error: TimeMilliseconds.second * 7,
  warning: TimeMilliseconds.second * 5.5,
  success: TimeMilliseconds.second * 4,
};

export const PROGRESS_COLOR_MAP: Record<Alert["type"], string> = {
  error: "#f74e31",
  success: "#5e23be",
  warning: "#ec9d1f",
};
