import { type PayloadAction } from "@reduxjs/toolkit";

import { SearchProvidersResult } from "api/buy";

import { ReducerState } from "./duck/types";
import { getGroupedProviders } from "./duck/selectors";

const reducers = {
  setProviders: (
    state: ReducerState,
    { payload }: PayloadAction<SearchProvidersResult>,
  ) => {
    state.providers = getGroupedProviders(payload.data);
  },
};

export default reducers;
