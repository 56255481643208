import { type PayloadAction } from "@reduxjs/toolkit";

import { ReducerState } from "./duck/types";
import { coinsAdapter } from "./duck/operations";

const reducers = {
  clearCoins: (state: ReducerState) => {
    coinsAdapter.removeAll(state);
    state.isNextCoins = true;
  },

  setIsFavorite: (
    state: ReducerState,
    action: PayloadAction<{ id: Coin["letterId"]; isFavorite: boolean }>,
  ) => {
    const { id, isFavorite } = action.payload;
    state.entities[id].isFavorite = isFavorite;
  },

  unsetFavorites: (state: ReducerState) => {
    Object.values(state.entities).forEach(coin => {
      coin.isFavorite = false;
    });
  },
};

export default reducers;
