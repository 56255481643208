import addressesAPI from "api/addresses";

import { NetworkError } from "operations/error";
import { createAsyncServerThunk } from "operations/redux";

import { cookieStorageData } from "./duck/operations";

export const loadAddressesServer = createAsyncServerThunk(
  "addresses/loadAddressesServer",
  async () => {
    try {
      return await addressesAPI.fetchAddresses();
    } catch (error) {
      if (error instanceof NetworkError && error.responseStatus === 401) {
        return Object.values(cookieStorageData.walletAddresses);
      }

      throw error;
    }
  },
);
