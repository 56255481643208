import {
  DEFAULT_USD_LIMITS,
  MAX_BUY_AMOUNT,
  MIN_BUY_AMOUNT,
} from "constants/currencies";

import { loadCurrencies } from "./thunks";

import { CurrenciesBuilder } from "./duck/types";
import { currenciesAdapter } from "./duck/operations";

const extraReducers = (builder: CurrenciesBuilder) => {
  //#region loadCurrencies

  builder
    .addCase(loadCurrencies.pending, state => {
      state.error = null;
      state.isLoading = true;
    })
    .addCase(loadCurrencies.fulfilled, (state, action) => {
      const processedCurrencies = action.payload.map(
        ({ limits, rate, ...restCurrency }) => {
          const usdLimits = limits.length
            ? limits.map(limit => ({
                ...limit,
                minBuyAmountUSD: limit.minBuyAmount
                  ? limit.minBuyAmount / rate
                  : MIN_BUY_AMOUNT,
                maxBuyAmountUSD: limit.maxBuyAmount
                  ? limit.maxBuyAmount / rate
                  : MAX_BUY_AMOUNT,
              }))
            : DEFAULT_USD_LIMITS;

          const [first, ...rest] = usdLimits;
          const amountLimits = rest.reduce(
            (acc, item) => ({
              min: Math.min(acc.min, item.minBuyAmountUSD),
              max: Math.max(acc.max, item.maxBuyAmountUSD),
            }),
            {
              min: first.minBuyAmountUSD,
              max: first.maxBuyAmountUSD,
            },
          );

          return {
            ...restCurrency,
            rate,
            default: false,
            amountLimits,
          };
        },
      );

      currenciesAdapter.setAll(state, processedCurrencies);
      state.isLoaded = true;
      state.isLoading = false;
    })
    .addCase(loadCurrencies.rejected, (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
      state.isLoaded = true;
    });

  //#endregion
};

export default extraReducers;
