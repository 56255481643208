import { Currencies, USD_CURRENCY } from "constants/currencies";

import { RootState } from "store/duck/types";

export const selectAccount = (state: RootState) => state.account.data;

export const getIsAuthorized = (state: RootState) =>
  Boolean(selectAccount(state));

export const selectCurrency = ({
  account: { currency, data },
  storage,
  currencies: { entities },
}: RootState): CurrencyWithLimits => {
  let code = Currencies.usd as string;
  if (currency) {
    code = currency;
  } else if (data) {
    code = data.currency.name;
  } else if (storage.currency) {
    code = storage.currency;
  }

  return entities[code] || USD_CURRENCY;
};
