import { createSelector } from "@reduxjs/toolkit";
import { memoize, reduce } from "lodash";

import { RootState } from "store/duck/types";

import { RegionsDictionary } from "./types";

export const selectRegions = (state: RootState) => state.regions.data;

export const getAllRegionsMap = memoize((regions: RegionsDictionary) =>
  reduce(
    regions,
    (acc, region) => {
      acc[region.letterId] = region;

      if (region.states) {
        return { ...acc, ...region.states };
      }

      return acc;
    },
    {} as Record<Region["letterId"], Region>,
  ),
);

export const selectAllRegionsMap = createSelector(
  [(state: RootState) => state.regions.data],
  getAllRegionsMap,
);
